const CITY_WROCLAW = "Wrocław";
const CITY_GDANSK = "Gdańsk";
const CITY_RZESZOW = "Rzeszów";
const CITY_WARSZAWA = "Warszawa";
const CITY_KRAKOW = "Kraków";
const REMOTE = "Remote";
const UK = "UK";
const GERMANY = "Germany";
const BELGIUM = "Belgium";
const BULGARIA = "Bulgaria";
const POLAND = "Poland";
const MOLDOVA = "Moldova";
const ROMANIA = "Romania";
const LONDON = "London";
const SWITZERLAND = "Switzerland";
const PORTUGAL = "Portugal";
const CZECH_REPUBLIC = "Czech Republic";

const cities = [
  [CITY_WROCLAW, "wroclaw"],
  [CITY_GDANSK, "gdansk"],
  [CITY_RZESZOW, "rzeszow"],
  [CITY_WARSZAWA, "warszawa"],
  [REMOTE, "remote"],
  [UK, "uk"],
  [BULGARIA, "bulgaria"],
  [MOLDOVA, "moldova"],
  [ROMANIA, "romania"],
  [LONDON, "london"],
  [SWITZERLAND, "switzerland"],
  [GERMANY, "germany"],
  [BELGIUM, "belgium"],
  [PORTUGAL, "portugal"],
  [CZECH_REPUBLIC, "czech-republic"],
  [POLAND, "poland"],
];

const citiesInPoland = [CITY_WROCLAW, CITY_GDANSK, CITY_RZESZOW, CITY_WARSZAWA, CITY_KRAKOW];

const citiesAbroad = [
  UK,
  GERMANY,
  BELGIUM,
  BULGARIA,
  MOLDOVA,
  ROMANIA,
  LONDON,
  SWITZERLAND,
  PORTUGAL,
  CZECH_REPUBLIC,
  POLAND,
];

function normalize(arr) {
  return (val) => arr.find((c) => c[1] === val)[0];
}

const normalizeCity = normalize(cities);

const deNormalizeCities = (cities) => cities?.map((city) => removeDiacritics(city.toLowerCase()));

const stringifyCities = (cities) =>
  cities?.map((city, index) => (index === 0 ? `${city}` : ` / ${city}`)).join("");

const deNormalizeTech = (tech, map) => map[tech];
const normalizeTech = (tech, map) => {
  const normalizedTechArray = Object.entries(map).find(([, v]) => v === tech);
  return normalizedTechArray ? normalizedTechArray[0] : undefined;
};

function isCity(cityOrTech) {
  const citiesAll = cities.map((c) => c[1]);

  return cityOrTech.split(",").some((city) => citiesAll.indexOf(city) > -1);
}

const isCityParam = (param) => {
  const citiesAll = cities.map((c) => c[1]);
  return citiesAll.indexOf(param) > -1;
};

const isTechnologyParam = (string, technologiesNormalized) => {
  const technologiesArray = Object.values(technologiesNormalized);
  return technologiesArray.some((technology) => string === technology);
};

const getOfferLink = (offer, technologies) => {
  const offerCities = deNormalizeCities(offer.cities);
  const offerTechnology = deNormalizeTech(offer.technology, technologies);

  return `/${offerCities}/${offerTechnology}/${offer.seoSlug}#job-description`;
};

const removeDiacritics = (text) => {
  return text
    .replace("ą", "a")
    .replace("ä", "a")
    .replace("ć", "c")
    .replace("ę", "e")
    .replace("ł", "l")
    .replace("ń", "n")
    .replace("ó", "o")
    .replace("ö", "o")
    .replace("ś", "s")
    .replace("ß", "ss")
    .replace("ü", "u")
    .replace("ź", "z")
    .replace("ż", "z");
};

const parseFullName = (userObject) => {
  const name = userObject.firstName.charAt(0).toUpperCase() + userObject.firstName.slice(1);
  const surname = userObject.lastName.charAt(0).toUpperCase() + userObject.lastName.slice(1);

  return name + " " + surname;
};

const createTrackingElement = (track) => {
  switch (track.provider) {
    case "linkedin":
      return {
        __html: `<img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=${track.partnerId}&conversionId=${track.conversionId}&fmt=gif" />`,
      };
  }
};

const removeHtmlTags = (html) => html.replace(/<\/?[^>]+(>|$)/g, "");

const sendGa = (category, label) => {
  if (typeof window.ga !== "undefined" && label) {
    window.ga(() => {
      if (!window.tracker) {
        window.tracker = window.ga.getAll()[0];
      }
      window.tracker.send("event", category, "click", label);
    });
  }
};

const sendGa4 = (eventName, params = {}) => {
  if (typeof window.gtag !== "undefined" && eventName) {
    window.gtag("event", eventName, { ...params });
  }
};

const gtagReportConversion = () => {
  window.gtag("event", "conversion", { send_to: "AW-415721186/yQRaCLzE2LgDEOLNncYB" });
  return false;
};

export {
  normalizeCity,
  deNormalizeCities,
  stringifyCities,
  normalizeTech,
  deNormalizeTech,
  isCity,
  isCityParam,
  isTechnologyParam,
  getOfferLink,
  removeDiacritics,
  parseFullName,
  createTrackingElement,
  removeHtmlTags,
  sendGa,
  sendGa4,
  gtagReportConversion,
  citiesInPoland,
  citiesAbroad,
};
